<template>
  <div class="block-width top-margin mx-auto white rounded mt-7 pa-2 pb-8 pa-sm-3 pb-sm-10">
    <div
      class="
        line-height-1
        border-error
        d-flex
        justify-center
        align-center
        rounded
        font-weight-medium
        text-uppercase
        text-body-2
        py-4
        mb-5
        mb-sm-8
      "
    >
      <v-icon size="16" color="error" class="mr-2">
        $blocked
      </v-icon>
      {{ $t('add_funds.replenishment') }}
    </div>
    <div class="secondary-darken--text text-center mb-3">
      {{ $t('add_funds.payment_not_credited') }}
    </div>
    <div class="line-height-1 d-flex justify-center">
      <router-link :to="{ name: routeNames.ADD_FUNDS }">
        {{ $t('add_funds.try_again') }}
      </router-link>
    </div>
  </div>
</template>

<script>
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'AddFundsError',
    data() {
      return {
        routeNames
      }
    }
  }
</script>

<style scoped lang="scss">
  .top-margin {
    @media screen and (min-width: 600px) {
      margin-top: 80px !important;
    }
    @media screen and (min-width: 1440px) {
      margin-top: 104px !important;
    }
  }
  .block-width {
    max-width: 328px;
    @media screen and (min-width: 600px) {
      max-width: 569px;
    }
  }
  .line-height-1 {
    line-height: 1;
  }
  .border-error {
    border: 1px solid var(--v-error-base);
  }
</style>
